<template>
  <div class="an-meeting">
    <h1 class="header-title_sub">
      Что происходит на собраниях Анонимных Наркоманов?
    </h1>
    <p class="text-block">
      Если вы собираетесь на свое первое собрание сообщества «Анонимные
      Наркоманы», наверное, не помешает знать заранее, что это такое.<br />
      Люди приходят в сообщество АН по самым разным причинам, но цель каждого
      собрания – предоставить членам АН место, где можно делиться опытом
      выздоровления с другими зависимыми. Если вы не являетесь зависимым, ищите
      «открытое собрание», на котором могут присутствовать люди, не являющиеся
      зависимыми (в нашем
      <router-link class="router-link" to="/timetable">расписании</router-link>
      или по телефону, указанному на сайте).
    </p>
    <h2 class="header-title_sub">Участие бесплатно</h2>
    <p class="text-block">
      Собрания групп АН абсолютно бесплатны. Это не учебные занятия и не сеансы
      групповой терапии. Мы не преподаем и не консультируем. Мы не оказываем
      медицинской, финансовой, социальной или юридической помощи. Мы просто
      делимся нашим личным опытом, связанным с зависимостью и выздоровлением.
    </p>
    <h2 class="header-title_sub">Участие анонимно</h2>
    <p class="text-block">
      Мы не сотрудничаем ни с какими другими организацями и никому не
      предоставляем сведений о наших членах.<br />Нас не интересует, сколько или
      какие наркотики вы употребляли, у кого вы их брали и что вы делали в
      прошлом. Для нас главное то, как зависимость и выздоровление влияют на
      наши жизни. Из уважения к анонимности наших членов, мы просим приходящих
      на наши собрания людей не рассказывать о том, кто наши члены и чем они
      делятся на собраниях.
    </p>
    <h2 class="header-title_sub">Новички и гости</h2>
    <p class="text-block">
      Новичков и гостей обычно просят представиться по имени (можно
      представиться вымышленным именем, так как сообщество анонимное). Новичков,
      как правило, приветствуют рукопожатием или объятием и вручают брелок
      «Добро пожаловать».
    </p>
    <h2 class="header-title_sub">Закрытые и открытые собрания</h2>
    <p class="text-block">
      <router-link class="router-link" to="/timetable"
        >Наши собрания</router-link
      >
      доступны для любого зависимого ежедневно. Если у Вас лично нет проблем с
      наркотиками, но Вам интересен опыт выздоровления в Сообществе «Анонимные
      Наркоманы», то специально для Вас мы проводим «открытые» собрания.
      Уточните место и время проведения открытых собраний в нашем
      <router-link class="router-link" to="/timetable">расписании</router-link>
      или по телефону, указанному на сайте.<br />
      Наши собрания сильно отличаются друг от друга по количеству присутствующих
      и стилю проведения. Некоторые из них маленькие и уютные, другие – большие
      и шумные. Правила проведения собраний различаются в разных местах. Важнее
      всего то, что мы делимся нашим опытом, силами и надеждой. Если вы
      зависимый, возвращайтесь снова и примите участие в нашем выздоровлении!
    </p>
    <router-link class="router-link" to="/timetable"
      >Расписание собраний АН город Смоленск</router-link
    >
  </div>
</template>

<script>
export default {
  created() {
    document.title = "Что происходит на собраниях";
  },
};
</script>